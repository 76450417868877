import React, { useState, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import slugify from 'slugify';

import Metadata from '../components/Metadata';
import PageLayout from '../layouts/Page';
import Hero from '../components/Hero';
import PancakesStack from '../components/PancakesStack';

import './landing-page.scss';

export const query = graphql`
  query LandingPageQuery($id: String!) {
    landingPage: sanityLandingPage(_id: { eq: $id }) {
      _id
      pageTitle
      hero {
        heroText
        heroTitle
        heroOverlappingImage {
          altText
          image {
            ...ImageWithPreview
          }
        }
        heroRightImage {
          altText
          image {
            ...ImageWithPreview
          }
        }
        heroCallToActions {
          _rawCallToActions
        }
        showStickyNav
        youtubeVideo {
          videoID
        }
        heroLogo {
          companyLogo {
            logoFullColorLight {
              asset {
                url
              }
            }
            logoMonochromeLight {
              asset {
                url
              }
            }
          }
          customLogo {
            altText
            image {
              asset {
                url
              }
            }
          }
        }
      }
      slug {
        current
      }
      seo {
        metaDescription
        metaTitle
        ogImage {
          asset {
            url
          }
        }
      }
      pancakes {
        ...SanityPancakeText
        ...SanityPancakeCards
        ...SanityPancakeTextImage
        ...SanityPancakePreFooter
        ...SanityPancakeFeaturesHighlight
        ...SanityPancakeCustomerQuote
        ...SanityPancakeCustomerQuotes
        ...SanityPancakeExplore
        ...SanityPancakeVideo
        ...SanityTrySourceControlProvider
        ...SanityPancakeLogos
        ...SanityPancakeJobs
        ...SanityTeamPancake
        ...SanitySpeakersPancake
        ...SanityPancakeSchedule
        ...SanityPancakeHubspotForm
        ...SanityPancakeCalendly
        ...SanityBlogPostsListing
        ...SanityPancakeVideosList
        ...SanityPancakeTextSidebar
        ...SanityTabs
        ...SanityPancakeProductTour
        ...SanityPancakeBenefits
        ...SanityPancakeCaseStudies
      }
    }
  }
`;

const LandingPage = (props) => {
  const { landingPage } = props.data;
  const pageClass = `page-${slugify(landingPage.pageTitle, { lower: true })}`;

  let logo;
  if (landingPage.hero?.heroLogo?.customLogo?.image.asset.url) {
    logo = landingPage.hero.heroLogo.customLogo.image.asset.url;
  } else if (landingPage.hero?.heroLogo?.companyLogo?.logoFullColorLight) {
    logo = landingPage.hero.heroLogo.companyLogo.logoFullColorLight.asset.url;
  } else if (landingPage.hero?.heroLogo?.companyLogo?.logoMonochromeLight) {
    logo = landingPage.hero.heroLogo.companyLogo.logoMonochromeLight.asset.url;
  }

  return (
    <PageLayout className={pageClass}>
      <Metadata
        title={landingPage.seo?.metaTitle || landingPage.pageTitle}
        description={landingPage.seo?.metaDescription}
        image={landingPage.seo?.ogImage?.asset.url || null}
        bodyClass={pageClass + '-body'}
      />
      <Hero
        overlappingImage={landingPage.hero?.heroOverlappingImage}
        rightImage={landingPage.hero?.heroRightImage}
        youtubeId={landingPage.hero?.youtubeVideo?.videoID}
        showStickyNav={landingPage.hero?.showStickyNav}
      >
        {logo && (
          <Hero.Image>
            <img src={logo} alt="" />
          </Hero.Image>
        )}
        {landingPage.hero?.heroTitle && (
          <Hero.Title>{landingPage.hero.heroTitle}</Hero.Title>
        )}
        {landingPage.hero?.heroText && (
          <Hero.Text>{landingPage.hero.heroText}</Hero.Text>
        )}
        {landingPage.hero?.heroCallToActions && (
          <Hero.CallToActions
            callToActions={landingPage.hero.heroCallToActions._rawCallToActions}
          />
        )}
      </Hero>
      <PancakesStack pancakes={landingPage.pancakes} />
    </PageLayout>
  );
};

export default LandingPage;
